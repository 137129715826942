import React from 'react';
import { useTranslation } from 'react-i18next';
import MainLayout from '../layouts/main';
import Container from 'common/src/components/UI/Container';
import BannerSection from '../containers/Hosting/Banner';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';

export default function() {
  const { t } = useTranslation();

  const termsData = [
    t('terms.list.item1'),
    t('terms.list.item2'),
    t('terms.list.item3'),
    t('terms.list.item4'),
    t('terms.list.item5'),
    t('terms.list.item6'),
    t('terms.list.item7'),
    t('terms.list.item8'),
    t('terms.list.item9'),
    t('terms.list.item10'),
    t('terms.list.item11'),
    t('terms.list.item12'),
    t('terms.list.item13'),
    t('terms.list.item14'),
    t('terms.list.item15'),
    t('terms.list.item16'),
    t('terms.list.item17'),
    t('terms.list.item18'),
    t('terms.list.item19'),
    t('terms.list.item20'),
    t('terms.list.item21'),
    t('terms.list.item22'),
    t('terms.list.item23'),
    t('terms.list.item24'),
    t('terms.list.item25'),
    t('terms.list.item26'),
    t('terms.list.item27'),
    t('terms.list.item28'),
    t('terms.list.item29'),
    t('terms.list.item30'),
    t('terms.list.item31'),
    t('terms.list.item32'),
    t('terms.list.item33'),
    t('terms.list.item34'),
    t('terms.list.item35'),
    t('terms.list.item36'),
    t('terms.list.item37'),
    t('terms.list.item38'),
    t('terms.list.item39'),
    t('terms.list.item40'),
    t('terms.list.item41'),
    t('terms.list.item42'),
    t('terms.list.item43'),
    t('terms.list.item44'),
    t('terms.list.item45'),
    t('terms.list.item46'),
    t('terms.list.item47'),
    t('terms.list.item48'),
    t('terms.list.item48_1'),
    t('terms.list.item48_2'),
    t('terms.list.item48_3'),
    t('terms.list.item49'),
    t('terms.list.item50'),
    t('terms.list.item50_1'),
    t('terms.list.item50_2'),
    t('terms.list.item51'),
    t('terms.list.item52'),
    t('terms.list.item53'),
    t('terms.list.item54'),
    t('terms.list.item55'),
    t('terms.list.item56'),
    t('terms.list.item57'),
    t('terms.list.item58'),
    t('terms.list.item59'),
    t('terms.list.item60'),
    t('terms.list.item61'),
    t('terms.list.item62'),
    t('terms.list.item63'),
    t('terms.list.item64')
  ];

  return (
    <MainLayout title={t('terms.title')}>
      <BannerSection content={t('terms.title')} />
      <Container>
        <Box>
          <Heading style={{ color: '#fff' }} content={t('terms.topic')} />
        </Box>
        {termsData.length > 0 &&
          termsData.map((data, index) => (
            <Text
              key={index}
              style={{
                color: '#496b96',
                fontSize: '20px',
                lineHeight: '38px'
              }}
              content={data}
            />
          ))}
      </Container>
    </MainLayout>
  );
}
